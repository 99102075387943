<template>
  <div class="font">
    <ayl-berad-nav :nav="nav"/>
     <div class="content-box mt20px">
      <div class="content-main">
        <ayl-table :table="table">
          <div slot="ctrl-button">
            <!-- <div class="export-btn"><u>查询数据导出</u></div> -->
            <el-button class="ml40px" type="info" style="float: right;" @click="dialogExport=true" plain>导出</el-button>
          </div>
        </ayl-table>

        <!-- 人工处理详情 -->
        <el-dialog
        title="处理详情"
        :visible.sync="detailsDialogVisible"
        width="30%"
        top="30vh"
        center>
        <div class="detail-main">
          <span>处理人：</span>
          <span>{{basicTitleData.dealName}}</span>
        </div>
        <div class="detail-main">
          <span>处理时间：</span>
          <span>{{basicTitleData.dealTime}}</span>
        </div>
        <div class="detail-main">
          <span>处理方式：</span>
          <span>{{basicTitleData.dealType}}</span>
        </div>
        <div class="detail-main">
          <span>处理内容：</span>
          <span>{{basicTitleData.dealContent}}</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="detailsDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="detailsDialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>

        <!--导出-->
        <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
            <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
          </el-checkbox-group>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
            <el-button @click="dialogExport=false">取 消</el-button>
          </span>
        </el-dialog>
        <!-- 查看附件 -->
        <el-dialog title="查看附件" :visible.sync="dialogFormVisible">
          <div class="attachment-main">
            <div class="left-img">
              <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" style="width: 100%" v-if="videoIdx === 1"></video>
              <img :src="url===''?playerOptions.images[0]:url" class="images" v-image-preview v-else>
            </div>
            <div class="right-img">
              <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" class="images" @click="videoItem(1)"></video>
              <img v-for="(item, idx) in playerOptions.images" :key="idx" @click="chg(idx,0)" :src="item" class="images">
            </div>
          </div>
      </el-dialog>
      </div>
     </div>
  </div>
</template>

<script>
import mixins from '../../../utils/mixins'
  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      // eslint-disable-next-line no-unused-vars
      const vm = this
      return {
        nav: [{name: "证据中心"}],
        table: {
          api: vm.$api_hw.alarmManage_listAlarmEvidence,
          query: {
            bidId: null,
            alarmIdentifierList: [],
            startTime: null,
            endTime: null,
            dealStatus: null,
            alarmType: null,
            dealName: null,
            queryContent: null,
          },
          // 是否显示分页
          // hidePagination: true,
          // 表格查询的条件组件
          searchData: [{
            type: "cascader_radio",
            title: "标段名称",
            model: "bidId",
            placeholder: "请选择",
            option: [],
            width: "180px"
          }, {
              type: 'cascader',
              title: '报警类型',
              model: 'alarmIdentifierList',
              placeholder: '请选择',
              option: vm.$enums.AlarmNames.list,
            },{
              type: 'select',
              title: '报警等级',
              model: 'alarmType',
              placeholder: '请选择',
              option: vm.$enums.alarmType.list
            }, {
              type: 'dateSection',
              title: '开始时间',
              isOptions: true,
              model: [],
              modelName: ['startTime', 'endTime'],
            },
            {
              type: 'select',
              title: '处理状态',
              model: 'dealStatus',
              placeholder: '请选择',
              option: vm.$enums.alarmState.list
            },
            {
              type: 'select',
              title: '处理人',
              model: 'dealName',
              placeholder: '请选择',
              option: []
            },
            {
              type: 'input',
              model: 'queryContent',
              placeholder: '车牌号/司机'
            }],
          columns: [
              {
              title: '序号',
              width: '50px',
              $index: 'index',
            },
            {
              title: '车牌号',
              key: 'plateNumber',
              width: '120px',
            }, {
              title: '所属企业',
              showTooltip: true,
              key: 'company',
            }, {
              title: '所属标段',
              showTooltip: true,
              key: 'company',
            }, {
              title: '驾驶员姓名',
              width: '100px',
              key: 'driverName',
            }, {
              title: '报警类型',
              width: '140px',
              key: 'alarmIdentifier',
            }, {
              title: '报警等级',
              width: '100px',
              key: 'alarmType',
            }, {
              title: '报警开始时间',
              key: 'alarmStartTime',
              filter: 'str2ymdhm',
              width: '170px',
            }, {
              title: '处理状态',
              width: '100px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view-hw': ctx.row.dealStatus === 1 ? true : false,
                    },
                    on: {
                      click: ctx.row.dealStatus === 1 ? vm.detailsDeal.bind(this, ctx.row) : vm.goNull.bind(this, ctx.row) 
                    }
                  }, ctx.row.dealStatusDes||'- -')
                ])
              }
            }, {
              title: '处理人',
              width: '100px',
              key: 'dealUserName',
            }, {
              title: '附件',
              width: '100px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view-hw': ctx.row.attache === 1 ? true : false,
                    },
                    on: {
                      click: ctx.row.attache === 1 ? vm.annex.bind(this, ctx.row) : vm.goNull.bind(this, ctx.row)
                    }
                  }, ctx.row.attache === 1 ? '预览' :  '暂无')
                ])
              }
            }, {
              title: '操作',
              width: '155px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view-hw': true,
                    },
                    on: {
                      click: vm.details.bind(this, ctx.row)
                    }
                  }, '查看详情')
                ])
              }
            }]
        },

        list: [],
        dialogExport: false,
        checkList: [],
        checkIndex: [],
        btnLoading: false,
        tableLoading: false,
        code: 1,
        dialogFormVisible: false,

        detailsDialogVisible: false,
        basicTitleData: {
          dealName: null,
          dealTime: null,
          dealType: null,
          dealContent: null,
        },

        props: { multiple: true },

        url: '',
        videoUrl: '',
        videoIdx: 1,
        playerOptions : {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [],
            images: [],
            poster: "", //你的封面地址
            notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true  //全屏按钮
            }
        },
      }
    },

    methods:{
      // 导出
      async exportExcel(){
        this.btnLoading = true
        try {
          const url = await this.$api.alarmEvidenceExport({
            alarmIdentifierList: this.table.query.alarmIdentifierList,
            alarmType: this.table.query.alarmType,
            startTime: this.table.query.time[0],
            endTime: this.table.query.time[1],
            dealStatus: this.table.query.dealStatus,
            dealName: this.table.query.dealName,
            queryContent: this.table.query.queryContent,
            excelKey: 'listAlarmDealExport',
            indexList: this.checkIndex,
          })
          window.open(url)
          this.dialogExport = false
        // eslint-disable-next-line no-empty
        }catch (e) {}
        this.btnLoading = false
      },
      /**
       * 人工处理--处理详情
       */
      async detailsDeal(val){
        this.detailsDialogVisible = true
        let detailsData= await this.$api_hw.alarmManage_deviceAlarmDetail({
          alarmName: val.alarmName,
          alarmType: val.alarmType,
          deviceId: val.deviceId,
          alarmStartTime: val.alarmStartTime,
          tenantId: val.tenantId
        })
        this.basicTitleData.dealName = detailsData.dealUserName
        this.basicTitleData.dealTime = this.$dayjs(detailsData.dealTime).format('YYYY-MM-DD HH:mm:ss')
        this.basicTitleData.dealType = detailsData.dealType
        this.basicTitleData.dealContent = detailsData.dealContent
      },
      async annex(val){
        this.playerOptions.images = []
        this.playerOptions.sources = []
        this.videoUrl = ''
        this.url = ''
        this.videoItem(1)
        this.dialogFormVisible = true
        
        let videoData = await this.$api.findFileByPrefix({
          tenantId: val.tenantId,
          attacheKey: val.attacheKey
        })
        this.playerOptions.images = videoData[0].images
        this.playerOptions.sources = videoData[0].sources
        this.videoUrl = this.playerOptions.sources[0].src
        //this.$router.push({path:'/safety-center/alarm-management/annexs',query: { attacheKey: val.attacheKey }});
      },
      details(val){    //报警未处理
        console.log(val.alarmName)
        this.$router.push({path:'/alarm-management/evidence-center/evidence-dispose-details',
        query: { 
            deviceId: val.deviceId, 
            alarmStartTime: val.alarmStartTime, 
            alarmType: val.alarmType, 
            tenantId: val.tenantId, 
            alarmName: val.alarmName}
        });
      },
      goNull(){
        this.$router.push({path:''});
      },

      chg:function(ind,val){
        this.url =this.playerOptions.images[ind]
        this.videoIdx = val
      },
      videoItem(val){
        this.videoIdx = val
      }
    },
    async mounted () {
      this.table.searchData[0].option = await this.$api_hw.common_getBidNameAndIdList({});
      let dealingData = await this.$api.getDealUserInfo({});  
      this.table.searchData[5].option = dealingData.map(e =>{
        return {
          label: e.dealUserName,
          value: e.dealName
        }
      })                //处理人
      // await this.$search(this.table)
      // 导出
      this.$api.GetExcelExportKeyAndColumn({
        excelKey: "listAlarmDealExport"
      }).then((res)=>{
        this.checkList = res.chsList
        this.checkIndex = this.checkList.map(e=>{
          return e.index
        })
      })
    }
  }
</script>
<style lang='sass' scoped>
.font
  font-family: MicrosoftYaHei,
.attachment-main
    width: 100%
    .right-img
      width: 20%
      height: 520px
      display: inline-block
      vertical-align: top
      margin-left: 2%
      overflow-y: auto
      .images
        width: 100%
    .left-img
      width: 78%
      height: 520px
      display: inline-block
      .images
        width: 100%
        height: 100%
    .class
      display: block
    .show
      display: none
/deep/ .table-view-edit
  padding-right: 10px
  color: #99c0ff

/deep/ .el-form--inline .el-form-item
  margin-right: 40px
/deep/ .el-table th > .cell
  color: rgba(41, 41, 41, 1)
  font-size: 14px

/deep/ .el-table .cell
  color: rgba(102, 102, 102, 1)
  font-size: 14px

.pagination
  width: 100%
  float: right
/deep/ .content-box .content-main
  padding: 24px 24px 74px
.content-box /deep/ .el-form-item
  width: auto
/deep/ .el-button--primary 
    color: #FFFFFF
    background-color: #1ABC9C
    border-color: #1ABC9C

.detail-main
  margin: 0px 30px
  font-size: 14px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(102,102,102,1)
  line-height: 46px
</style>

